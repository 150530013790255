import { Autocomplete } from 'stimulus-autocomplete'
import { turboRequest } from '../../helpers/turbo_helper'

export default class CustomAutocomplete extends Autocomplete {
  static targets = [
    'selectedMenuItemContainer',
    'menuItemSelectionTurboFrame',
    'extraListContainer',
    'extendedListMessage',
    'spinner',
    'inputWrapper',
    'addNewItemContainer'
  ]
  static values = {
    extendListOptions: Object,
    selectMenuItemUrl: String,
    selectNewClientPath: String,
    hideAddNewItemButton: Boolean,
    autocompleteParameters: Object,
    ransackSearchMatcher: String,
    uniqueId: String
  }

  connect() {
    super.connect()
    this.currentUrl = this.urlValue

    this.displayItemSelection = this.displayItemSelection.bind(this)
    this.showLoadingSpinner = this.showLoadingSpinner.bind(this)
    this.hideLoadingSpinner = this.hideLoadingSpinner.bind(this)

    this.element.addEventListener('autocomplete.change', this.displayItemSelection)
    this.element.addEventListener('loadstart', this.showLoadingSpinner)
    this.element.addEventListener('loadend', this.hideLoadingSpinner)
    this.hideLoadingSpinner()
  }

  disconnect() {
    this.element.removeEventListener('autocomplete.change', this.displayItemSelection)
    this.element.removeEventListener('loadstart', this.showLoadingSpinner)
    this.element.removeEventListener('loadend', this.hideLoadingSpinner)
  }

  showLoadingSpinner() {
    this.spinnerTarget.classList.remove('d-none')
  }

  hideLoadingSpinner() {
    this.spinnerTarget.classList.add('d-none')
  }

  displayItemSelection() {
    this.selectedMenuItemContainerTarget.classList.remove('d-none')
    this.inputWrapperTarget.classList.add('d-none')

    var url = new URL(this.selectMenuItemUrlValue)
    var params = new URLSearchParams(url.search)
    params.append('item_id', this.hiddenTarget.value)

    if (this.uniqueIdValue) {
      params.append('unique_id', this.uniqueIdValue)
    }

    turboRequest(`${url.pathname}?${params}`)

    this.element.dispatchEvent(
      new CustomEvent('typeahead:changed', { bubbles: true, detail: { id: this.uniqueIdValue } })
    )
  }

  buildURL(query) {
    const url = new URL(this.currentUrl, window.location.href)
    url.searchParams.set(`q[${this.ransackSearchMatcherValue}]`, query)
    url.searchParams.set('query_string', query)
    if (this.hasAutocompleteParametersValue) {
      Object.keys(this.autocompleteParametersValue).forEach(key =>
        url.searchParams.set(key, this.autocompleteParametersValue[key])
      )
    }
    return url.href
  }

  newClient() {
    if (this.selectNewClientPathValue) {
      var urlParams = new URLSearchParams(this.selectNewClientPathValue)
      urlParams.append('full_name', this.inputTarget.value)

      let url = ''
      if (this.selectNewClientPathValue.includes('?')) {
        url = `${this.selectNewClientPathValue}&${urlParams}`
      } else {
        url = `${this.selectNewClientPathValue}?${urlParams}`
      }

      turboRequest(url)
    }
    this.close()
  }

  switchUrl() {
    if (this.currentUrl == this.urlValue) {
      this.currentUrl = this.extendListOptionsValue.extended_path
    } else {
      this.currentUrl = this.urlValue
    }

    this.onInputChange()
  }

  extraListContainerTargetConnected(element) {
    if (Object.keys(this.extendListOptionsValue).length === 0) {
      element.remove()
    } else {
      if (this.currentUrl == this.urlValue) {
        this.extendedListMessageTarget.innerHTML = this.extendListOptionsValue.extended_message
      } else {
        this.extendedListMessageTarget.innerHTML = this.extendListOptionsValue.revert_extended_message
      }
    }
  }

  addNewItemContainerTargetConnected(element) {
    if (this.hideAddNewItemButtonValue) {
      element.remove()
    }
  }

  hiddenTargetConnected(element) {
    if (element.value) {
      this.displayItemSelection()
    }
  }

  clear() {
    super.clear()
    this.selectedMenuItemContainerTarget.classList.add('d-none')
    this.inputWrapperTarget.classList.remove('d-none')
    this.menuItemSelectionTurboFrameTarget.innerHTML = ''

    this.element.dispatchEvent(
      new CustomEvent('autocomplete.clear', { bubbles: true, detail: { id: this.uniqueIdValue } })
    )
  }

  hideAndRemoveOptions() {
    this.close()
  }

  commit(selected) {
    if (selected.getAttribute('aria-disabled') === 'true') return

    if (selected instanceof HTMLAnchorElement) {
      selected.click()
      this.close()
      return
    }

    const textValue = selected.getAttribute('data-autocomplete-label') || selected.textContent.trim()
    const value = selected.getAttribute('data-autocomplete-value') || textValue
    this.inputTarget.value = textValue

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = value
      this.hiddenTarget.dispatchEvent(new Event('input'))
      this.hiddenTarget.dispatchEvent(new Event('change'))
    } else {
      this.inputTarget.value = value
    }

    this.inputTarget.focus()
    this.hideAndRemoveOptions()

    this.element.dispatchEvent(
      new CustomEvent('autocomplete.change', {
        bubbles: true,
        detail: { value: value, textValue: textValue, selected: selected, id: this.uniqueIdValue }
      })
    )
  }
}
