import { Controller } from '@hotwired/stimulus'
import { debounce } from 'underscore'

const ACTIVITY_ACTION_EVENTS = ['mousemove', 'wheel']
const START_TIMEOUT_COUNTDOWN_WITHIN = 70000 // 70 seconds in miliseconds
const WARNING_ELEMENT_ID = 'session-timeout-alert'
const TEMPLATE = secondsLeft => {
  return `
  <div id="${WARNING_ELEMENT_ID}" class="d-flex alert alert-warning align-items-center" role="alert">
    <div class="box box-warning">
      <i class="fa fa-check mx-3"></i>
    </div>
    <div class="flex-grow-1 alert-text">
      <div class="line-height">
        <span class="text-warning mb-3">Warning</span>
        <div id="flash_notice">
          Your session will automatically logout due to inactivity in ${secondsLeft} seconds.
        </div>
      </div>
    </div>
  </div>
  `
}

export default class extends Controller {
  static values = {
    sessionTimeoutPath: String,
    sessionTimeoutRedirectPath: String,
    initialTimeoutAt: String
  }

  connect() {
    this.startPolling = this.startPolling.bind(this)
    this.resetTimeoutAt = this.resetTimeoutAt.bind(this)
    this.pollForUpdatedTimeout = this.pollForUpdatedTimeout.bind(this)
    this.sendHeartbeat = debounce(this.sendHeartbeat.bind(this), 300, true)
    this.debouncedSendHeartbeat = debounce(this.sendHeartbeat, 30000, true)

    ACTIVITY_ACTION_EVENTS.forEach(event => window.addEventListener(event, this.debouncedSendHeartbeat))

    this.resetTimeoutAt(new Date(this.initialTimeoutAtValue))
  }

  disconnect() {
    ACTIVITY_ACTION_EVENTS.forEach(event => window.removeEventListener(event, this.debouncedSendHeartbeat))
  }

  // Figure out when the session will expire, then check within 1 minute of expiring.
  // Other pages can make the session valid. This prevents constant polling of session expiry.
  resetTimeoutAt(timeoutAt) {
    clearInterval(this.intervalId)
    this.timeoutAt = timeoutAt
    let secondsLeft = this.secondsLeft(timeoutAt)
    console.debug(`SESSION LIMIT[resetTimeoutAt]: ${secondsLeft} seconds`)
    this.updateTimeoutWarning(secondsLeft)
    const startPollingIn = this.timeoutAt - new Date() - START_TIMEOUT_COUNTDOWN_WITHIN
    this.timeoutId = setTimeout(this.startPolling, startPollingIn)
  }

  secondsLeft(timeoutAt) {
    return Math.round((timeoutAt - new Date()) / 1000)
  }

  // Check the session timeout controller to see when the session actually expires.
  startPolling() {
    this.intervalId = setInterval(this.pollForUpdatedTimeout, 1000)
  }

  async pollForUpdatedTimeout() {
    let secondsLeft = this.secondsLeft(this.timeoutAt)

    if (secondsLeft % 10 === 0 || secondsLeft <= 0) {
      await fetch(this.sessionTimeoutPathValue, { cache: 'no-cache' })
        .then(response => response.json())
        .then(json => {
          if (json.timed_out) {
            clearInterval(this.intervalId)
            window.location = this.sessionTimeoutRedirectPathValue
          } else {
            this.timeoutAt = new Date(json.session_expires_at)
          }
        })
    }

    secondsLeft = this.secondsLeft(this.timeoutAt)
    this.updateTimeoutWarning(secondsLeft)
  }

  sendHeartbeat() {
    clearInterval(this.intervalId)
    clearTimeout(this.timeoutId)

    if (!this.sessionTimeoutPathValue) return console.warn('Session Timeout Path is not defined')

    fetch(this.sessionTimeoutPathValue, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
      }
    })
      .then(response => response.json())
      .then(json => this.resetTimeoutAt(new Date(json.session_expires_at)))
  }

  updateTimeoutWarning(secondsLeft) {
    const warningElement = document.getElementById(WARNING_ELEMENT_ID)

    console.debug(`SESSION LIMIT[updateTimeoutWarning]: ${secondsLeft} seconds`)

    if (secondsLeft > 75) {
      ACTIVITY_ACTION_EVENTS.forEach(event => window.removeEventListener(event, this.sendHeartbeat))
    } else {
      ACTIVITY_ACTION_EVENTS.forEach(event => window.addEventListener(event, this.sendHeartbeat))
    }

    if (secondsLeft > 60) {
      if (warningElement) {
        warningElement.remove()
      }
    } else if (secondsLeft >= 0) {
      const renderedTemplate = TEMPLATE(secondsLeft)
      if (warningElement) {
        warningElement.outerHTML = renderedTemplate
      } else {
        document.body.insertAdjacentHTML('afterbegin', renderedTemplate)
      }
    }
  }
}
